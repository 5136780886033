@import './styles/material.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins', sans-serif; }

th:first-child,
td:first-child {
  padding-left: 40px;
}

th{
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  padding: 15px 8px 15px 8px;
  border-bottom: 1px solid black;
}

td{
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  padding: 10px 5px 10px 5px;
  color: #767676;
  border-top: 0.1px groove #767676;
}

table {
  border-collapse: separate !important;
  border-spacing: 0;
  width: 90%;
  // margin: 30px;
  border: 4px;
}

span{
  font-family: Poppins
}

p{
  font-family: Poppins
}

h2{
  font-family: Poppins
}

mat-form-field {
  width: 100%;
}

.mat-tab-label{
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
}

.mat-tab-label-active{
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-family: Poppins;
  color: #1071C1
}

.mat-paginator-container{
  background-color: #fafafa;
}

.mat-checkbox-label {
  font-weight: 400;
}

.mat-ink-bar{
  background-color: #1071C1 !important
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #1071C1;
}

.mat-input-element .mat-form-field-autofill-control .ng-tns-c126-2 .ng-pristine .ng-invalid .cdk-text-field-autofill-monitored .ng-touched .focus-visible{
  caret-color: #1071C1 !important;
}

.mat-badge-content{
  background-color: #1071C1 !important;
}

.mat-menu-content{
  max-height: 220px;
  overflow-y: auto;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background{
  background-color: #1071C1;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
  color: #1071C1;
}

@media (max-width: 500px) {
  .mat-tab-label{
    font-size: 12px;
  }
  .mat-tab-label-active{
    font-size: 12px;
  }
}